import React, { FC, useState } from 'react';

import styled from './ForgotPassword.module.css';
import Button from '../../components/Button/Button';
import PasswordService from '../../services/PasswordService';
import { useHistory } from 'react-router';
import { LOGIN } from '../../constants/Routes';
import { userNameRegex } from '../../constants/Validation';
import { AxiosResponse } from 'axios';
import ApiErrorModel from '../../models/ApiErrorModel';
import Input from '../../components/Input/Input';
import Modal from '../../components/Modal/Modal';

interface Props {
    onClose: () => void;
}

const ForgotPassword: FC<Props> = ({ onClose }) => {
    const [username, setUsername] = useState<string>('');
    const history = useHistory();
    const [noMatchError, setNoMatchError] = useState<string | undefined>(undefined);
    const [error, setError] = useState<string | undefined>(undefined);
    const [noMatch, setNoMatch] = useState<boolean>(false);
    const [message, setMessage] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const handleUsernameChange = (value: string): void => {
        if (noMatch) {
            setError(undefined);
        }

        const replace = value.replace(/\s/, '');
        setUsername(replace);
    };

    const handlePostEmail = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        setError(undefined);
        setMessage(null);
        setLoading(true);
        setNoMatch(false);

        PasswordService()
            .postForgotPasswordEmail(username)
            .then(res => {
                if (res.status == 204) {
                    setUsername('');
                    setNoMatch(true);
                    setLoading(false);
                    setNoMatchError('Vi hittade tyvärr inget konto med den angivna epostadressen, vänligen prova med en annan.');
                } else {
                    setMessage('Ett återställningsmail har skickats.');
                    setSubmitted(true);
                }

                setLoading(false);
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                setError('Något gick fel. Försök igen.');
                setLoading(false);
            });
    };

    const validEmail = userNameRegex(username);

    return (
        <div>
            {!submitted && !error && (
                <Modal
                    title={!error ? 'Glömt lösenord' : 'Epostadress saknas'}
                    subtitle={!noMatchError ? 'Inga problem, fyll i din epostadress så hjälper vi dig!' : noMatchError}
                    className={styled.Modal}
                >
                    <form onSubmit={handlePostEmail} className={styled.ForgotPasswordForm}>
                        <div className={styled.FormGroup}>
                            <Input
                                type="text"
                                placeholder={'Epost'}
                                error={noMatch ? ' ' : ''}
                                className={styled.Input}
                                value={username}
                                onChange={handleUsernameChange}
                                autoComplete="username"
                            />
                        </div>
                        <div className={styled.FormGroupRow}>
                            <Button variant="Outline" onClick={onClose}>
                                Avbryt
                            </Button>
                            <Button variant="Primary" className={styled.Submit} disabled={!validEmail || loading || submitted} type="submit">
                                Återställ lösenord
                            </Button>
                        </div>
                    </form>
                </Modal>
            )}
            {submitted && !error && (
                <Modal
                    title={'Återställningsmeddelande skickat'}
                    subtitle={`Vi har skickat ett epostmeddelande till ${username} med instruktioner om hur du ändrar ditt lösenord.`}
                    className={styled.ModalConfirm}
                >
                    <div className={styled.Content}>
                        <p className={styled.Info}>
                            <strong>OBS! </strong>länken gäller enbart i 10min.
                        </p>

                        <div className={[styled.FormGroupRowConfirm, styled.FormGroupRow].join(' ')}>
                            <Button variant="Primary" onClick={onClose}>
                                Stäng
                            </Button>
                        </div>
                    </div>
                </Modal>
            )}

            {!submitted && error && (
                <Modal title={'Serverproblem'} subtitle={'Servern går inte att nå för tillfället, vänligen försök igen senare.'} className={styled.ModalConfirm}>
                    <div className={[styled.FormGroupRowConfirm, styled.FormGroupRow].join(' ')}>
                        <Button variant="Primary" onClick={onClose}>
                            Stäng
                        </Button>
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default ForgotPassword;
