import React, { useEffect, useMemo } from 'react';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import RevisionModel from '../../../../models/RevisionModel';
import RevisionCell from '../../../../components/Table/RevisionCell/RevisionCell';
import MobileTable from '../../../../components/Mobile/Table/MobileTable';
import { REVISION_EDIT } from '../../../../constants/Routes';
import useFinishedRevision from '../../../../hooks/useFinishedRevision';
import StatusOk from '../../../../components/Mobile/StatusIcon/RevisionStatusOk/StatusOk';
import StatusNotOk from '../../../../components/Mobile/StatusIcon/RevisionStatusNotOk/StatusNotOk';
import styled from './MobileFinishedRevision.module.css';
import FinishedRevisionIcon from '../../../../assets/images/finishedRevisionIcon.svg';
import EmptyListView from '../../../../components/EmptyListView/EmptyListView';
import backArrow from '../../../../assets/images/backIcon.svg';

const MobileFinishedRevision = () => {
    const { loading, revisions, startDate, endDate, fetchData, sorting } = useFinishedRevision();
    const history = useHistory();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        fetchData(startDate, endDate);
    }, [startDate, endDate]);

    const columns = useMemo(() => {
        const columns: Array<Column<RevisionModel> & { Header: string; languageId?: string }> = [
            {
                id: 'name',
                Header: t('columnName'),
                languageId: 'columnName',
                Cell: function nameCell(props) {
                    return <RevisionCell {...props} multiRow></RevisionCell>;
                },
                accessor: row => row.name,
                sortType: (rowA, rowB): number => {
                    const lawA = rowA.original.name.toLowerCase();
                    const lawB = rowB.original.name.toLowerCase();
                    if (lawA < lawB) {
                        return -1;
                    }
                    if (lawA > lawB) {
                        return 1;
                    }
                    return 0;
                },
            },
            {
                id: 'date',
                Header: t('columnDate'),
                languageId: 'columnDate',
                Cell: RevisionCell,
                accessor: row => row.finishedAt,
                sortInverted: true,
            },
            {
                id: 'status',
                Header: '',
                languageId: 'columnStatus',
                width: 0.5,
                Cell: function Cell(props) {
                    const value = props.row.original.revisionOk ? <StatusOk /> : <StatusNotOk />;
                    return (
                        <RevisionCell
                            {...{
                                ...props,
                                cell: { ...props.cell, value },
                            }}
                        />
                    );
                },
                accessor: row => +row.revisionOk,
            },
            {
                id: 'responsible',
                Header: t('columnResponsible'),
                languageId: 'columnResponsible',
                Cell: RevisionCell,
                accessor: row => row.responsibleName,
            },
        ];

        return columns;
    }, [i18n.language]);

    const openRevision = (selectedRevision: any) => {
        return {
            onClick: () => {
                history.push(REVISION_EDIT + `/${selectedRevision.id}`);
            },
        };
    };

    return (
        <>
            <p className={styled.backButton} onClick={history.goBack}>
                <img className={styled.backImage} src={backArrow} />
                {t('back')}
            </p>
            <div className={styled.Header}>
                <img src={FinishedRevisionIcon} alt="ramboll" width="50px" />
                <span className={styled.Title}>{t('contextMenuFinishedRevisions')}</span>
            </div>
            {loading && <LoadingSpinner />}
            <MobileTable data={revisions} columns={columns} sorting={sorting} onSelectRevision={openRevision} />
            {!loading && revisions && revisions.length < 1 ? <EmptyListView title={t('emptyListViewNoFinishedRevisionsTitle')} /> : null}
        </>
    );
};

export default MobileFinishedRevision;
