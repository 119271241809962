import { AxiosResponse } from 'axios';
import { getAuthApi } from '../config/axios.config';
import ApiErrorModel from '../models/ApiErrorModel';

const ReportService = () => {
    const axiosInstance = getAuthApi();

    function getRevisionStatusReport(companyId: number, from: string, to: string): Promise<void> {
        return axiosInstance
            .get(`/report/revision/status?companyId=${companyId}&from=${from}&to=${to}`, { responseType: 'blob' })
            .then((response: AxiosResponse) => {
                const blob = new Blob([response.data]);
                saveAs(blob, `Statusrapport revision ${new Date().toLocaleDateString('sv')}.xlsx`);
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function getRevisionResultReport(companyId: number, from: string, to: string): Promise<void> {
        return axiosInstance
            .get(`/report/revision/result?companyId=${companyId}&from=${from}&to=${to}`, { responseType: 'blob' })
            .then((response: AxiosResponse) => {
                const blob = new Blob([response.data]);
                saveAs(blob, `Resultatrapport revision ${new Date().toLocaleDateString('sv')}.xlsx`);
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    return {
        getRevisionResultReport,
        getRevisionStatusReport,
    };
};

export default ReportService;
